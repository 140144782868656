import { Button, Card, CardBody, Form } from "react-bootstrap";
import { Action, Match, prizeConfigMessage } from "../../constants";
import { useEffect, useState } from "react";
import { restrictAlpha } from "../../../utils/userUtil";
import { showFailureToaster } from "../../../utils/apiUtil";

const FlatRewardForm = ({
  isReadOnly,
  prizeList,
  setPrizeList,
  questionNumber,
  setRemainingQuestionsCount,
  setShowToaster,
  isActiveRow,
}) => {
  const [newPrize, setNewPrize] = useState({
    from: questionNumber,
    to: questionNumber,
    coins: "",
  });
  useEffect(() => {
    setNewPrize({ from: questionNumber, to: questionNumber, coins: "" });
  }, [isReadOnly, prizeList, questionNumber]);

  const validateFlatPrizeList = (newPrizeList) => {
    const fromSet = new Set();
    for (const obj of newPrizeList) {
      if (fromSet.has(obj.from)) {
        return false;
      }
      fromSet.add(obj.from);
    }

    return true;
  };

  const handleAddPrize = () => {
    if (!isReadOnly) {
      const fromPrize = parseInt(newPrize.from);
      const toPrize = parseInt(newPrize.from);
      const coins = parseInt(newPrize.coins);

      if (isNaN(fromPrize) || isNaN(toPrize) || isNaN(coins)) {
        setShowToaster(
          showFailureToaster(prizeConfigMessage.prizeShouldBeNumber),
        );
      } else if (fromPrize < 0 || toPrize < 0 || coins < 0) {
        setShowToaster(
          showFailureToaster(prizeConfigMessage.prizeCantNegative),
        );
      } else {
        const newPrizeList = [
          ...prizeList,
          {
            from: fromPrize,
            to: toPrize,
            coins: coins,
          },
        ];
        if (!validateFlatPrizeList(newPrizeList)) {
          setShowToaster(
            showFailureToaster(prizeConfigMessage.questionNumberAlreadyExists),
          );
          return;
        } else {
          const sortedPrizeList = newPrizeList?.sort(
            (a, b) => b?.from - a?.from,
          );
          setPrizeList(sortedPrizeList);
          setRemainingQuestionsCount((prev) => prev - 1);
        }
      }
    }
  };

  const handleNewPrizeConfig = (key, e) => {
    if (restrictAlpha(e.nativeEvent.data) || !e.nativeEvent.data) {
      setNewPrize((prevPrize) => ({ ...prevPrize, [key]: e.target.value }));
    }
  };
  return (
    <>
      <Card className="add-prize-card w-100">
        <CardBody className="add-prize-card-body">
          <div className="d-flex">
            <div className="input-div w-50">
              <Form.Control
                type="input"
                id="from-input"
                className="border-left-styl"
                placeholder={Match.configure.correctQuestionNumber}
                onChange={(e) => !isReadOnly && handleNewPrizeConfig("from", e)}
                value={newPrize.from}
                disabled={isReadOnly}
              />
            </div>
            <div className="input-div w-50">
              <Form.Control
                type="input"
                id="from-input"
                placeholder={Match.configure.amountText}
                onChange={(e) => handleNewPrizeConfig("coins", e)}
                value={newPrize.coins}
                disabled={isReadOnly}
              />
            </div>
            <div className="input-div">
              <Button
                className="add-prize-button"
                disabled={isReadOnly || !isActiveRow}
                onClick={() => handleAddPrize()}
              >
                {Action.addNewPrize}
              </Button>
            </div>
          </div>
        </CardBody>
      </Card>
    </>
  );
};

export default FlatRewardForm;
