//All API URLs will be put here.

const VERSION = "v1";
export const URLS = {
  TODOLIST_URL: `/todos`,
  USER_URL: `/${VERSION}/user`,
  MATCH_LIST_URL: `/${VERSION}/match/list`,
  PLAYER_LIST_URL: `/${VERSION}/player/search`,
  PLAYER_URL: `/${VERSION}/player`,
  TEAM_LIST_URL: `/${VERSION}/team`,
  MATCH_URL: `/${VERSION}/match`,
  FUTY_POINTS: `/${VERSION}/futyPoints`,
  REFERRALS: `/${VERSION}/promotion`,
  DEACTIVATE_REFERRAL: `/${VERSION}/promotion/deactivate/{promotionId}`,
  BONUS_CREDIT: `/${VERSION}/userCredit`,
  PUSH_NOTIFICATION: `/${VERSION}/notification`,
  REWARD: `/${VERSION}/reward`,
  ADD_REWARD: `/${VERSION}/reward/add`,
  DELETE_REWARD: `/${VERSION}/reward/{rewardId}`,
  GET_BRANDS: `/${VERSION}/reward/brand?allBrands=true`,
  ADD_BRAND: `/${VERSION}/reward/brand`,
  UPDATE_BRAND: `/${VERSION}/reward/brand/{brandId}`,
  PRESIGNED_URL: `/${VERSION}/reward/brand/presignurl?filename={fileName}`,
  USER_STATS: `/${VERSION}/user/stats?startDate={startDate}&endDate={endDate}`,
  USER_TEAM_STATS: `/${VERSION}/userTeam/stats`,
  REFERRAL_STATS: `/${VERSION}/promotion?usersCount=true`,
  TEAMLOGO_PRESIGNED_URL: `/${VERSION}/team/files/presignurl?filename={fileName}`,
  HOME_JERSEY_PRESIGNED_URL: `/${VERSION}/team/presignUrl/homeJerseyLogo?filename={fileName}`,
  AWAY_JERSEY_PRESIGNED_URL: `/${VERSION}/team/presignUrl/awayJerseyLogo?filename={fileName}`,
  PLAYERIMAGE_PRESIGNED_URL: `/${VERSION}/player/files/presignurl?filename={fileName}`,
  TRIVIA_BANNER_PRESIGNED_URL: `/${VERSION}/trivia/presignUrl/banner?filename={fileName}`,
  USER_DEATAILS: `/${VERSION}/user`,
  LEAGUES_URL: `/${VERSION}/league`,
  POPULATE_LEAGUES_SEASON: `/${VERSION}/league/masterData/dump`,
  MATCH_USERS_TEAMS: `/${VERSION}/userTeam`,
  USER_IDENTITY_URL: `/${VERSION}/userIdentity`,
  MINI_LEAGUE: `/${VERSION}/miniLeague`,
  MINI_LEAGUE_LOGO_PRESIGNED_URL: `/${VERSION}/miniLeague/presignurl?filename={fileName}`,
  RETENTION_VIEW_URL: `/${VERSION}/team/gameweekRetention`,
  QUIZ_LIST_URL: `/${VERSION}/tripleThreatQuiz/list`,
  QUIZ_URL: `/${VERSION}/tripleThreatQuiz`,
  QUIZ_SAVE_URL: `/${VERSION}/tripleThreatQuiz`,
  GOALFEST_LIST_URL: `/${VERSION}/goalFest/list`,
  GOALFEST_URL: `/${VERSION}/goalFest`,
  STREAK: `/${VERSION}/streak`,
  TASK_LIST_URL: `/${VERSION}/task/list`,
  CREATE_TASK_URL: `/${VERSION}/task`,
  UPDATE_TASK_URL: `/${VERSION}/task/{taskId}`,
  DELETE_TASK_URL: `/${VERSION}/task/{taskId}`,
  DEFAULT_TELEGRAM_METRICS_URL: `${VERSION}/metrics/telegramDefault`,
  TRIVIA_LIST_URL: `/${VERSION}/trivia/list`,
  TRIVIA_URL: `/${VERSION}/trivia`,
};
