import { Field, FieldArray, Form, Formik } from "formik";
import { Button, Row, Form as BsForm, Col } from "react-bootstrap";
import Typography from "../../../common/components/Typography";
import { Action } from "../../../common/constants";
import { useLocation, useNavigate } from "react-router-dom";
import Select from "react-select";
import {
  currencyTypes,
  earningTypes,
  getAvailableEarningTypesOptions,
  getAvailableGameModeOptions,
  getDefaultConfigValues,
  initialCreateSubscriptionValues,
  subscriptionSchema,
  SubscriptionTypeEnum,
  subscriptionTypes,
} from "../../subscriptionUtils";
import { gameModesOptions } from "../../../Tasks/tasksUtils";
import { useEffect, useState } from "react";

const SubscriptionForm = () => {
  const [initialSubscriptionValues, setInitialSubscriptionFormValues] =
    useState(initialCreateSubscriptionValues);
  const navigate = useNavigate();
  const location = useLocation();

  const { edit, data } = location?.state ?? {};

  useEffect(() => {
    if (edit && data) {
      setInitialSubscriptionFormValues(data);
    }
  }, [location, edit, data]);

  const handleSubmit = async (values: any) => {
    console.log(values);
  };
  return (
    <>
      <Button
        className="d-flex align-items-start px-0"
        variant="link"
        onClick={() => navigate(-1)}
      >
        {Action.goBack}
      </Button>
      <Typography variant="pageTitle">
        {edit ? "Update" : "Create"} Subscription
      </Typography>
      <section className="mt-2">
        <Row>
          <Formik
            initialValues={initialSubscriptionValues}
            validationSchema={subscriptionSchema}
            validateOnBlur={true}
            validateOnChange={false}
            enableReinitialize={true}
            onSubmit={handleSubmit}
          >
            {({ errors, touched, values, setFieldValue, setFieldError }) => {
              const addConfig = () => {
                if (values.type === SubscriptionTypeEnum.DEFAULT) {
                  return {
                    earningType: getAvailableEarningTypesOptions(
                      values?.configuration,
                    )?.at(0)?.value,
                    multiplier: 1,
                  };
                }
                if (values.type === SubscriptionTypeEnum.ONE_TIME) {
                  return {
                    gameMode: getAvailableGameModeOptions(
                      values?.configuration,
                    )?.at(0)?.value,
                    teamsPerMatch: 1,
                  };
                }
              };

              return (
                <Form className="d-flex flex-column gap-4">
                  <Row>
                    <Col md={6} className="position-relative">
                      <BsForm.Label>Select Type</BsForm.Label>
                      <Select
                        placeholder="Select subscription type"
                        options={subscriptionTypes}
                        name="type"
                        value={subscriptionTypes.find(
                          (option) => option.value === values.type,
                        )}
                        onChange={(option) => {
                          setFieldValue("type", option.value);
                          setFieldValue(
                            "configuration",
                            getDefaultConfigValues(option.value),
                          );
                          setFieldError("type", undefined);
                        }}
                      />
                      {errors?.type && touched.type && (
                        <Typography className="text-danger fs-10 position-absolute">
                          {errors?.type as string}
                        </Typography>
                      )}
                    </Col>
                    <Col className="position-relative" md={4}>
                      <BsForm.Label>Version</BsForm.Label>
                      <Field
                        type="number"
                        name="version"
                        className="form-control"
                        min={0}
                      />
                      {errors?.version && touched.version && (
                        <Typography className="text-danger fs-10 position-absolute">
                          {errors?.version as string}
                        </Typography>
                      )}
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6} className="position-relative">
                      <BsForm.Label>Amount</BsForm.Label>
                      <Field
                        type="number"
                        name="amount"
                        className="form-control"
                      />
                      {errors?.amount && touched.amount && (
                        <Typography className="text-danger fs-10 position-absolute">
                          {errors?.amount as string}
                        </Typography>
                      )}
                    </Col>
                    <Col md={4} className="position-relative">
                      <BsForm.Label>currency</BsForm.Label>
                      <Select
                        placeholder="Select currency"
                        options={currencyTypes}
                        name="currency_code"
                        value={currencyTypes.find(
                          (option) => option.value === values.currency_code,
                        )}
                        onChange={(option) => {
                          setFieldValue("type", option.value);
                          setFieldError("type", undefined);
                        }}
                      />
                      {errors?.currency_code && touched.currency_code && (
                        <Typography className="text-danger fs-10 position-absolute">
                          {errors?.currency_code as string}
                        </Typography>
                      )}
                    </Col>
                  </Row>
                  <Row>
                    <Col md={4} className="position-relative">
                      <BsForm.Label>Name</BsForm.Label>
                      <Field
                        name="name"
                        type="text"
                        placeholder="Name"
                        className="form-control"
                      />
                      {errors?.name && touched.name && (
                        <Typography className="text-danger fs-10 position-absolute">
                          {errors?.name as string}
                        </Typography>
                      )}
                    </Col>
                    <Col md={6} className="position-relative">
                      <BsForm.Label>Description</BsForm.Label>
                      <Field
                        name="description"
                        type="text"
                        placeholder="Description"
                        className="form-control"
                      />
                      {errors?.description && touched.description && (
                        <Typography className="text-danger fs-10 position-absolute">
                          {errors?.description as string}
                        </Typography>
                      )}
                    </Col>
                  </Row>
                  <Row>
                    <Col md={4} className="position-relative">
                      <BsForm.Label>Validity (in days)</BsForm.Label>
                      <Field
                        name="validity_days"
                        type="number"
                        placeholder="validity"
                        className="form-control"
                      />
                      {errors?.validity_days && touched.validity_days && (
                        <Typography className="text-danger fs-10 position-absolute">
                          {errors?.validity_days as string}
                        </Typography>
                      )}
                    </Col>
                  </Row>

                  <section className="border rounded">
                    <FieldArray name="configuration">
                      {({ push, remove }) => (
                        <div className="p-2 d-flex flex-column gap-3">
                          <Row className="d-flex justify-content-between align-items-center">
                            <Col>
                              <Typography variant="heading4">
                                Configuration
                              </Typography>
                            </Col>
                            <Col md={"auto"}>
                              <Button
                                variant="primary"
                                onClick={() => push(addConfig())}
                              >
                                Add
                              </Button>
                            </Col>
                          </Row>
                          {values?.type === SubscriptionTypeEnum.DEFAULT &&
                            values?.configuration?.map((_, index) => (
                              <Row
                                key={index}
                                className="d-flex justify-content-center align-items-end"
                              >
                                <Col md={4} className="position-relative">
                                  <BsForm.Label>Earning</BsForm.Label>
                                  <Select
                                    placeholder="Select earning type"
                                    options={
                                      getAvailableEarningTypesOptions(
                                        values?.configuration,
                                      ) || earningTypes
                                    }
                                    name={`configuration.${index}.earningType`}
                                    value={earningTypes?.find(
                                      (option) =>
                                        option.value ===
                                        values?.configuration[index]
                                          ?.earningType,
                                    )}
                                    onChange={(option) => {
                                      setFieldValue(
                                        `configuration.${index}.earningType`,
                                        option?.value,
                                      );
                                      setFieldError(
                                        `configuration.${index}.earningType`,
                                        undefined,
                                      );
                                    }}
                                  />
                                </Col>
                                <Col md={4} className="position-relative">
                                  <BsForm.Label>Multiplier</BsForm.Label>
                                  <Field
                                    name={`configuration.${index}.multiplier`}
                                    type="number"
                                    placeholder="Multiplier"
                                    className="form-control"
                                  />
                                  {errors.configuration?.[index]?.multiplier &&
                                    touched.configuration?.[index]
                                      ?.multiplier && (
                                      <Typography className="text-danger fs-10 position-absolute">
                                        {
                                          errors.configuration?.[index]
                                            ?.multiplier
                                        }
                                      </Typography>
                                    )}
                                </Col>
                                {values?.configuration.length > 1 && (
                                  <Col md={3}>
                                    <Button
                                      variant="danger"
                                      onClick={() => remove(index)}
                                    >
                                      Remove
                                    </Button>
                                  </Col>
                                )}
                              </Row>
                            ))}
                          {values?.type === SubscriptionTypeEnum.ONE_TIME &&
                            values?.configuration.length > 0 &&
                            values?.configuration?.map((_, index) => (
                              <Row
                                key={index}
                                className="d-flex justify-content-center align-items-end"
                              >
                                <Col md={4} className="position-relative">
                                  <BsForm.Label>Game Mode</BsForm.Label>
                                  <Select
                                    placeholder="Select game mode"
                                    options={
                                      getAvailableGameModeOptions(
                                        values?.configuration,
                                      ) || gameModesOptions
                                    }
                                    name={`configuration.${index}.gameMode`}
                                    value={gameModesOptions?.find(
                                      (option) =>
                                        option.value ===
                                        values.configuration[index]?.gameMode,
                                    )}
                                    onChange={(option) => {
                                      setFieldValue(
                                        `configuration.${index}.gameMode`,
                                        option?.value,
                                      );
                                      setFieldError(
                                        `configuration.${index}.gameMode`,
                                        undefined,
                                      );
                                    }}
                                  />
                                </Col>
                                <Col md={4} className="position-relative">
                                  <BsForm.Label>
                                    No. of teams per match
                                  </BsForm.Label>
                                  <Field
                                    name={`configuration.${index}.teamsPerMatch`}
                                    type="number"
                                    min={1}
                                    placeholder="teamsPerMatch"
                                    className="form-control"
                                  />
                                  {errors.configuration?.[index]
                                    ?.teamsPerMatch &&
                                    touched.configuration?.[index]
                                      ?.teamsPerMatch && (
                                      <Typography className="text-danger fs-10 position-absolute">
                                        {
                                          errors.configuration?.[index]
                                            ?.teamsPerMatch
                                        }
                                      </Typography>
                                    )}
                                </Col>
                                {values.configuration.length > 1 && (
                                  <Col md={3}>
                                    <Button
                                      variant="danger"
                                      onClick={() => remove(index)}
                                    >
                                      Remove
                                    </Button>
                                  </Col>
                                )}
                              </Row>
                            ))}
                        </div>
                      )}
                    </FieldArray>
                  </section>
                  <Button type="submit" variant="primary" className="w-100">
                    {edit ? "Update" : "Create"} Subscription
                  </Button>
                </Form>
              );
            }}
          </Formik>
        </Row>
      </section>
    </>
  );
};

export default SubscriptionForm;
