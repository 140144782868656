import React from "react";
import Toast, { ToastProps } from "react-bootstrap/Toast";
import { Button, ToastContainer } from "react-bootstrap";
import classNames from "classnames";

interface IReactToastr extends ToastProps {
  toastBg: string;
  heading?: React.ReactNode;
  message: React.ReactNode;
  title: string;
  closeLabel?: string;
  closeVariant?: "white";
  closeButton?: boolean;
  onClose: (e?: React.MouseEvent | React.KeyboardEvent) => void;
  position?:
    | "top-start"
    | "top-center"
    | "top-end"
    | "middle-start"
    | "middle-center"
    | "middle-end"
    | "bottom-start"
    | "bottom-center"
    | "bottom-end";
  autohide?: boolean;
  delay?: number;
  show?: boolean;
  [key: string]: any;
}

const ReactToastr = ({
  toastBg,
  message,
  position,
  onClose,
  autohide = true,
  delay = 4000,
  closeButton,
  ...props
}: IReactToastr) => {
  return (
    <ToastContainer position={position}>
      <Toast
        autohide={autohide}
        delay={delay}
        onClose={onClose}
        className={classNames({
          "border-success bg-success-subtle": toastBg === "success",
          "border-warning bg-warning-subtle": toastBg === "warning",
          "border-danger bg-danger-subtle": toastBg === "danger",
        })}
        {...props}
      >
        <Toast.Body className="text-dark">
          <div
            className={classNames("toast-icon", {
              "border-success bg-success": toastBg === "success",
              "border-warning bg-warning": toastBg === "warning",
              "border-danger bg-danger": toastBg === "danger",
            })}
          >
            {toastBg === "success" && (
              <>
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M17 9C17 13.4186 13.4179 17 9 17C4.58213 17 1 13.4186 1 9C1 4.5814 4.58213 1 9 1C13.4179 1 17 4.58213 17 9Z"
                    stroke="white"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M4.65723 8.66322L7.54157 11.5476L13.2075 5.8772"
                    stroke="white"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </>
            )}
            {(toastBg === "warning" || toastBg === "danger") && (
              <>
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clipPath="url(#clip0_1_907)">
                    <g clipPath="url(#clip1_1_907)">
                      <path
                        d="M7.99971 1C7.5471 1 7.12964 1.22812 6.89831 1.62225L0.174073 13.0926C-0.0576379 13.4879 -0.0584103 13.9762 0.174073 14.3711C0.367937 14.7004 0.734426 15.0012 1.18781 15H14.8124C15.2654 15 15.6323 14.7004 15.8261 14.3711C16.0586 13.9762 16.0578 13.4879 15.8261 13.0926L9.10188 1.62225C8.87017 1.22812 8.45271 1 8.0001 1H7.99971ZM7.99971 1.87498C8.13488 1.87498 8.29514 1.96677 8.3577 2.07302L15.0819 13.5368C15.1445 13.6434 15.1445 13.8129 15.0819 13.9196C14.9808 14.0918 14.9472 14.125 14.8116 14.1246H1.18703C1.05148 14.1246 1.01789 14.0918 0.916705 13.9196C0.854143 13.8129 0.854143 13.6434 0.916705 13.5368L7.64095 2.07302C7.70351 1.96638 7.86416 1.87498 7.99894 1.87498H7.99932H7.99971ZM7.99971 4.71865C7.76066 4.71865 7.56718 4.91473 7.56718 5.15613V10.406C7.56718 10.6478 7.76105 10.8435 7.99971 10.8435C8.23837 10.8435 8.43224 10.6474 8.43224 10.406V5.15613C8.43224 4.91434 8.23837 4.71865 7.99971 4.71865ZM7.99971 11.7185C7.64133 11.7185 7.35092 12.0122 7.35092 12.3747C7.35092 12.7372 7.64133 13.0309 7.99971 13.0309C8.35809 13.0309 8.6485 12.7372 8.6485 12.3747C8.6485 12.0122 8.35809 11.7185 7.99971 11.7185Z"
                        fill="white"
                      />
                    </g>
                  </g>
                  <defs>
                    <clipPath id="clip0_1_907">
                      <rect width="16" height="16" fill="white" />
                    </clipPath>
                    <clipPath id="clip1_1_907">
                      <rect
                        width="16"
                        height="14"
                        fill="white"
                        transform="translate(0 1)"
                      />
                    </clipPath>
                  </defs>
                </svg>
              </>
            )}
          </div>
          <div className="flex-grow-1">{message}</div>

          <Button
            onClick={onClose}
            type="button"
            className="btn-close"
            aria-label="Close"
            data-dismiss="toast"
            variant="link"
          ></Button>
        </Toast.Body>
      </Toast>
    </ToastContainer>
  );
};

export default ReactToastr;
