import React, { useState, useEffect } from "react";
import { fetchFutyPoints, updateFutyPoints } from "../../service";
import { CustomStatusCode, HttpStatus } from "../../../utils/constants";
import "./style.scss";
import { Button, Col, Row, Spinner } from "react-bootstrap";
import { EMPTY_ERROR, MenuItems } from "../../../common/constants";
import ReactToastr from "../../../common/components/ReactToaster";
import Typography from "../../../common/components/Typography";
import TableWrapper from "../../../common/components/ReactTable/TableWrapper";
import ReactTable from "../../../common/components/ReactTable/ReactTable";
import { ColumnDef } from "@tanstack/react-table";
import ConfirmationModal from "../../../common/components/Modal/ConfirmationModel";

const EditableCell = ({
  cell: { value: initialValue },
  row: { original },
  column: { id: columnId },
  handleCellUpdate,
}) => {
  const [value, setValue] = useState(initialValue);

  const onChange = (e) => {
    setValue(e.target.value);
  };

  useEffect(() => {
    handleCellUpdate(original?.id, columnId, +value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  return <input type="number" value={value} onChange={onChange} />;
};

export const PointsTable = () => {
  const [pointsData, setPointsData] = useState<any>({
    data: null,
    isLoading: false,
    error: EMPTY_ERROR,
  });
  const [editedRowId, setEditedRowId] = useState<string>(null);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [isPointsLoading, setIsPointsLoading] = useState<boolean>(false);
  const [isUpdateInProgress, setIsUpdateInProgress] = useState<boolean>(false);
  const [attackerPoints, setAttackerPoints] = useState<number>(null);
  const [defenderPoints, setDefenderPoints] = useState<number>(null);
  const [midfielderPoints, setMidFielderPoints] = useState<number>(null);
  const [showToaster, setShowToaster] = useState<any>({
    show: false,
    heading: "",
    message: "",
    variant: "",
    route: "",
  });

  const handleClose = () => {
    setShowToaster((prev) => ({
      ...prev,
      show: false,
      message: "",
      variant: "",
      route: "",
    }));
  };

  useEffect(() => {
    getFutyPointsList();
  }, []);

  const getFutyPointsList = async (name?: string) => {
    setIsPointsLoading(true);
    const resData: any = await fetchFutyPoints();
    if (
      resData?.status === HttpStatus.REQUEST_SUCCEEDED ||
      resData?.status === HttpStatus.RESOURCE_CREATED
    ) {
      setPointsData((pre) => ({
        ...pre,
        isLoading: false,
        data: resData?.data?.data,
      }));
    } else {
      if (resData?.status !== CustomStatusCode.sessionExpire)
        setShowToaster((prev) => ({
          ...prev,
          show: true,
          message: `${
            resData?.message || "Something went wrong, please try again"
          }`,
          variant: "danger",
        }));
    }
    setIsPointsLoading(false);
  };

  const updateFutyPointsForCategory = async () => {
    setIsUpdateInProgress(true);
    const resData: any = await updateFutyPoints(editedRowId, {
      attacker: attackerPoints,
      defender: defenderPoints,
      midfielder: midfielderPoints,
    });

    if (
      resData?.status === HttpStatus.REQUEST_SUCCEEDED ||
      resData?.status === HttpStatus.RESOURCE_CREATED
    ) {
      const newPointsData = [...pointsData?.data];
      const newPointsObject = newPointsData?.find(
        (data) => data.id === editedRowId,
      );
      if (newPointsObject) {
        newPointsObject.attacker = attackerPoints;
        newPointsObject.defender = defenderPoints;
        newPointsObject.midfielder = midfielderPoints;

        setPointsData({
          ...pointsData,
          data: newPointsData,
        });
      }
    } else {
      if (resData?.status !== CustomStatusCode.sessionExpire)
        setShowToaster((prev) => ({
          ...prev,
          show: true,
          message: `${
            resData?.message || "Something went wrong, please try again"
          }`,
          variant: "danger",
        }));
    }
    setIsUpdateInProgress(false);
    setDefenderPoints(null);
    setMidFielderPoints(null);
    setAttackerPoints(null);
    setEditedRowId(null);
  };

  const columns = React.useMemo<ColumnDef<any, any>[]>(
    () => [
      {
        header: () => <span>{"Category"}</span>,
        accessorKey: "name",
        id: "name",
        cell: (info) => info.getValue(),
        canSort: false,
        colWidth: 300,
      },
      {
        header: () => <span>{"Attacker"}</span>,
        accessorKey: "attacker",
        id: "attacker",
        cell: (info) =>
          info?.row.original?.id === editedRowId ? (
            <EditableCell
              cell={{
                value: isUpdateInProgress ? attackerPoints : info.getValue(),
              }}
              row={info?.row}
              column={{ id: info?.column?.id }}
              handleCellUpdate={handleCellUpdate}
            />
          ) : (
            info.getValue()
          ),
        canSort: false,
        colWidth: 300,
      },
      {
        header: () => <span>{"Defender"}</span>,
        accessorKey: "defender",
        id: "defender",
        cell: (info) => {
          return info?.row.original?.id === editedRowId ? (
            <EditableCell
              cell={{
                value: isUpdateInProgress ? defenderPoints : info.getValue(),
              }}
              row={info?.row}
              column={{ id: info?.column?.id }}
              handleCellUpdate={handleCellUpdate}
            />
          ) : (
            info.getValue()
          );
        },
        canSort: false,
        colWidth: 300,
      },
      {
        header: () => <span>{"Mid Fielder"}</span>,
        accessorKey: "midfielder",
        id: "midfielder",
        cell: (info) => {
          return info?.row.original?.id === editedRowId ? (
            <EditableCell
              cell={{
                value: isUpdateInProgress ? midfielderPoints : info.getValue(),
              }}
              row={info?.row}
              column={{ id: info?.column?.id }}
              handleCellUpdate={handleCellUpdate}
            />
          ) : (
            info.getValue()
          );
        },
        canSort: false,
        colWidth: 300,
      },
      {
        header: () => <span>{"Action"}</span>,
        id: "action",
        cell: ({ row }) =>
          typeof editedRowId === "string" &&
          row.original?.id === editedRowId ? (
            <Row>
              <Col>
                <Button
                  variant="success"
                  size="sm"
                  onClick={() => {
                    setShowModal(true);
                  }}
                  disabled={isUpdateInProgress}
                >
                  {isUpdateInProgress ? "Updating..." : "Update"}
                </Button>
              </Col>
              <Col>
                <Button
                  variant="dark"
                  size="sm"
                  onClick={() => setEditedRowId(null)}
                  disabled={isUpdateInProgress}
                >
                  Cancel
                </Button>
              </Col>
            </Row>
          ) : (
            <span>
              <Button
                variant="light"
                size="sm"
                onClick={() => setEditedRowId(row.original?.id)}
              >
                Edit
              </Button>
            </span>
          ),
        colWidth: 300,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [editedRowId, isUpdateInProgress],
  );

  const handleCellUpdate = (rowId: string, columnId: string, value: number) => {
    if (columnId === "attacker" && editedRowId === rowId) {
      setAttackerPoints(value);
    }

    if (columnId === "defender" && editedRowId === rowId) {
      setDefenderPoints(value);
    }

    if (columnId === "midfielder" && editedRowId === rowId) {
      setMidFielderPoints(value);
    }
  };

  const handlePointUpdateConfirmation = () => {
    updateFutyPointsForCategory();
    setShowModal(false);
  };

  return isPointsLoading ? (
    <div className="react-table-loader">
      <Spinner animation="border" variant="primary" />
    </div>
  ) : (
    <>
      <Typography variant="pageTitle">{MenuItems.futyPoints}</Typography>
      <TableWrapper>
        <ReactTable
          isLoading={pointsData?.isLoading}
          data={pointsData?.data || []}
          columns={columns}
          totalRows={pointsData?.data?.length}
        />
      </TableWrapper>
      <ConfirmationModal
        variant="alert"
        show={showModal}
        title={"Are you sure you want to update futy points?"}
        handleClick={handlePointUpdateConfirmation}
        handleClose={() => setShowModal(false)}
        secondaryBtnText="Cancel"
        handleSecondaryBtnClick={() => setShowModal(false)}
        btnText="Confirm"
        infoText={
          "Please note: The updates will not be applied to published matches."
        }
      />
      <ReactToastr
        show={showToaster?.show}
        message={showToaster?.message}
        closeButton={true}
        title={showToaster?.heading}
        toastBg={showToaster?.variant}
        onClose={handleClose}
        position="top-center"
        autohide
      />
    </>
  );
};
