export const validateFlatTypeQuizPrizeConfig = (prizeArr, totalQuestions) => {
  if (prizeArr?.length !== totalQuestions) {
    throw new Error("Each question should have a reward amount");
  }
  const fromSet = new Set();

  for (const obj of prizeArr) {
    if (fromSet.has(obj.from)) {
      throw new Error("Duplicate question number found in PrizeConfig");
    }
    fromSet.add(obj.from);
  }

  return true;
};
