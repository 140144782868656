import * as Yup from "yup";
import {
  PrizeTypeEnum,
  QuestionsArrayLength,
  TriviaDifficulty,
  TriviaOptionsCount,
} from "../common/constants";

export const QuizSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  description: Yup.string().required("Description is required"),
  theme: Yup.string().required("Theme is required"),
  duration: Yup.object().shape({
    startTime: Yup.date()
      .required("Start time is required")
      .min(new Date(), "Start time cannot be in the past"),
    endTime: Yup.date()
      .required("End time is required")
      .min(Yup.ref("startTime"), "End time must be after start time"),
  }),
  questionConfigurations: Yup.array().of(
    Yup.object().shape({
      questions: Yup.array().of(
        Yup.object().shape({
          questionNumber: Yup.number(),
          question: Yup.string().required("Question is required"),
          correctOptionNumber: Yup.number().required(
            "Correct option is required",
          ),
          correctOptionValue: Yup.string(),
          options: Yup.array().of(
            Yup.object().shape({
              optionNumber: Yup.number(),
              option: Yup.string().required("Option is required"),
              countryCode: Yup.string().required("Country is required"),
              countryName: Yup.string(),
            }),
          ),
        }),
      ),
      prizes: Yup.array().of(
        Yup.object().shape({
          from: Yup.number(),
          to: Yup.number(),
          coins: Yup.number(),
        }),
      ),
      difficulty: Yup.string().required("Difficulty is required"),
      rewardType: Yup.string().required("Reward type is required"),
      questionsCount: Yup.number(),
    }),
  ),
});

export type CountryOption = {
  value: string;
  key: string;
  label: JSX.Element;
};

export const rewardTypeOptions = [
  {
    label: PrizeTypeEnum.FLAT,
    value: PrizeTypeEnum.FLAT,
  },
  {
    label: PrizeTypeEnum.ABSOLUTE,
    value: PrizeTypeEnum.ABSOLUTE,
  },
  {
    label: PrizeTypeEnum.PERCENTAGE,
    value: PrizeTypeEnum.PERCENTAGE,
  },
];

export const difficultyOptions = [
  {
    label: TriviaDifficulty.EASY,
    value: TriviaDifficulty.EASY,
  },
  {
    label: TriviaDifficulty.BALLER,
    value: TriviaDifficulty.BALLER,
  },
  {
    label: TriviaDifficulty.FREAK,
    value: TriviaDifficulty.FREAK,
  },
];

export const defaultQuestionPayload = (questionNumber: number) => ({
  questionNumber,
  question: "",
  correctOptionNumber: null,
  correctOptionValue: "",
  options: Array.from({ length: TriviaOptionsCount }, (_, idx) => ({
    optionNumber: idx + 1,
    countryCode: "",
    countryName: "",
    option: "",
  })),
});

export const defaultQuestionConfig = (
  difficulty: TriviaDifficulty,
  questionsCount: number,
) => ({
  questionsCount,
  rewardType: PrizeTypeEnum.FLAT,
  difficulty,
  questions: Array.from({ length: questionsCount }, (_, i) =>
    defaultQuestionPayload(i + 1),
  ),
  prizes: [],
});

export const initialTriviaValues = {
  name: "",
  description: "",
  theme: "",
  duration: { startTime: null, endTime: null },
  questionConfigurations: [
    defaultQuestionConfig(TriviaDifficulty.EASY, QuestionsArrayLength),
  ],
};

export const optionNumberOptions = Array.from(
  { length: TriviaOptionsCount },
  (_, idx) => ({
    label: idx + 1,
    value: idx + 1,
  }),
);
