import { Link } from "react-router-dom";
import Typography from "../../../common/components/Typography";
import { Button } from "react-bootstrap";
import { useCallback, useEffect, useState } from "react";
import { HttpStatus } from "../../../utils/constants";
import { EMPTY_ERROR } from "../../../common/constants";
import { deleteTriviaQuiz, fetchTriviaQuizList } from "../service";
import { TrueFalseIconViewer } from "../../../common/components/TrueFalseIconViewer";
import moment from "moment";
import TableWrapper from "../../../common/components/ReactTable/TableWrapper";
import ReactTable from "../../../common/components/ReactTable/ReactTable";
import ReactToastr from "../../../common/components/ReactToaster";
import ConfirmationModal from "../../../common/components/Modal/ConfirmationModel";
import "./style.scss";

const TriviaList = () => {
  const [triviaQuizList, setTriviaQuizList] = useState({
    data: null,
    isLoading: false,
    error: EMPTY_ERROR,
  });
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [deleteItemId, setDeleteItemId] = useState("");
  const [showToaster, setShowToaster] = useState<any>({
    show: false,
    heading: "",
    message: "",
    variant: "",
    route: "",
  });
  const handleToasterClose = () => {
    setShowToaster((prev) => ({
      ...prev,
      show: false,
      message: "",
      variant: "",
      route: "",
    }));
  };

  const getQuizList = useCallback(async () => {
    setTriviaQuizList((prev) => ({
      ...prev,
      isLoading: true,
      error: EMPTY_ERROR,
    }));
    const resData = await fetchTriviaQuizList();
    if (
      resData?.status === HttpStatus.REQUEST_SUCCEEDED ||
      resData?.status === HttpStatus.RESOURCE_CREATED
    ) {
      setTriviaQuizList((prev) => ({
        ...prev,
        isLoading: false,
        data: resData?.data?.data,
      }));
    } else {
      setTriviaQuizList({
        data: null,
        isLoading: false,
        error: { errorMsg: resData?.message, isError: true },
      });
      setShowToaster((prev) => ({
        ...prev,
        show: true,
        message: `${
          resData?.message ?? "Something went wrong, please try again"
        }`,
        variant: "danger",
      }));
    }
  }, []);

  const handleDeleteTrivia = async () => {
    setShowConfirmationModal(false);
    try {
      const res = await deleteTriviaQuiz({ quizId: deleteItemId });
      if (
        res?.status === HttpStatus.REQUEST_SUCCEEDED ||
        res?.status === HttpStatus.RESOURCE_CREATED
      ) {
        setShowToaster((prev) => ({
          ...prev,
          show: true,
          message: "Deleted",
          variant: "success",
        }));
        getQuizList();
      } else {
        throw Error(res?.message ?? "Something went wrong");
      }
    } catch (error) {
      setShowToaster((prev) => ({
        ...prev,
        show: true,
        message: error?.message,
        variant: "danger",
      }));
    }
  };

  const columns = [
    {
      accessorKey: "id",
      header: "Quiz Id",
      cell: (info) => info.getValue(),
      canSort: false,
    },
    {
      accessorKey: "name",
      header: "Name",
      cell: (info) => info.getValue(),
      canSort: false,
    },
    {
      accessorKey: "duration",
      id: "startDate",
      header: "Start Date",
      cell: (info) =>
        moment(info.getValue()?.startTime).format("DD/MM/YYYY (HH:mm)"),
    },
    {
      accessorKey: "duration",
      id: "endDate",
      header: "End Date",
      cell: (info) =>
        moment(info.getValue()?.endTime).format("DD/MM/YYYY (HH:mm)"),
    },
    {
      accessorKey: "isPublished",
      header: "Published",
      cell: (info) => (
        <TrueFalseIconViewer value={info?.getValue() ? true : false} />
      ),
      canSort: false,
    },
    {
      accessorKey: "createdAt",
      header: "Created At",
      cell: (info) => moment(info.getValue()).format("DD/MM/YYYY (HH:mm)"),
      canSort: false,
    },
    {
      id: "navigate",
      header: "Actions",
      cell: (info) => {
        const quizId = info.row.original.id;
        return (
          <div className="d-flex align-items-center">
            <Link to={`/trivia/${quizId}`} state={{ edit: true }}>
              <Button size="sm" className="nowrap">
                {info?.row?.original?.isPublished ? "View " : "Edit"}
              </Button>
            </Link>
            <Button
              onClick={() => {
                setShowConfirmationModal(true);
                setDeleteItemId(info?.row.original?.id);
              }}
              className="ms-2 nowrap"
              variant="danger"
              size="sm"
              disabled={info?.row?.original?.isPublished}
            >
              Delete
            </Button>
          </div>
        );
      },
      canSort: false,
    },
  ];

  useEffect(() => {
    getQuizList();
  }, [getQuizList]);

  return (
    <div>
      <div className="d-flex justify-content-between">
        <Typography variant="pageTitle" className="pb-2">
          Trivia Quiz List
        </Typography>
        <Link to="/trivia/create">
          <Button variant="primary">Create Quiz</Button>
        </Link>
      </div>
      <TableWrapper>
        <ReactTable
          isLoading={triviaQuizList?.isLoading}
          data={triviaQuizList?.data || []}
          hasError={triviaQuizList?.error?.isError}
          errorMessage={triviaQuizList?.error?.errorMsg}
          columns={columns}
          totalRows={triviaQuizList?.data?.length}
        />
      </TableWrapper>
      <ConfirmationModal
        variant="alert"
        show={showConfirmationModal}
        title={`Are you sure, you want to delete this trivia?`}
        handleClick={() => {
          handleDeleteTrivia();
        }}
        handleClose={() => setShowConfirmationModal(false)}
        secondaryBtnText="Cancel"
        handleSecondaryBtnClick={() => setShowConfirmationModal(false)}
        btnText="Delete"
      />
      <ReactToastr
        show={showToaster?.show}
        message={showToaster?.message}
        closeButton={true}
        title={showToaster?.heading}
        toastBg={showToaster?.variant}
        onClose={handleToasterClose}
        position="top-center"
        autohide
      />
    </div>
  );
};

export default TriviaList;
