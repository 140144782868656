import { Button, Col, Row } from "react-bootstrap";
import ReactToastr from "../../../common/components/ReactToaster";
import Typography from "../../../common/components/Typography";
import { Link } from "react-router-dom";
import TableWrapper from "../../../common/components/ReactTable/TableWrapper";
import ReactTable from "../../../common/components/ReactTable/ReactTable";
import ConfirmationModal from "../../../common/components/Modal/ConfirmationModel";
import { dummySubsData, SubscriptionTypeEnum } from "../../subscriptionUtils";
import { useEffect, useState } from "react";
import { EMPTY_ERROR } from "../../../common/constants";

const Subscriptions = () => {
  const [subscriptions, setSubscriptions] = useState<any>({
    data: null,
    isLoading: false,
    error: EMPTY_ERROR,
  });
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [deleteItemId, setDeleteItemId] = useState("");
  const [showToaster, setShowToaster] = useState<any>({
    show: false,
    heading: "",
    message: "",
    variant: "",
    route: "",
  });

  const handleToasterClose = () => {
    setShowToaster((prev) => ({
      ...prev,
      show: false,
      message: "",
      variant: "",
      route: "",
    }));
  };

  const fetchSubscriptions = async () => {
    setSubscriptions((prev) => ({
      ...prev,
      isLoading: false,
      data: dummySubsData,
    }));
  };

  useEffect(() => {
    fetchSubscriptions();
  }, []);

  const columns = [
    {
      accessorKey: "type",
      id: "type",
      header: "Type",
      cell: ({ row }) => {
        return (
          <span style={{ whiteSpace: "nowrap" }}>{row.original?.type}</span>
        );
      },
      canSort: false,
    },
    {
      accessorKey: "version",
      id: "version",
      header: "Version",
      cell: ({ row }) => {
        return <span>{row.original?.version}</span>;
      },
    },
    {
      accessorKey: "amount",
      id: "amount",
      header: "Amount",
      cell: ({ row }) => {
        return <span>{row.original?.amount}</span>;
      },
    },
    {
      accessorKey: "currency_code",
      id: "currency_code",
      header: "Currency",
      cell: ({ row }) => {
        return <span>{row.original?.currency_code}</span>;
      },
      canSort: false,
    },
    {
      accessorKey: "name",
      id: "name",
      header: "Name",
      cell: ({ row }) => {
        return <span>{row.original?.name}</span>;
      },
      canSort: false,
    },
    {
      accessorKey: "description",
      id: "description",
      header: "Description",
      cell: ({ row }) => {
        return <span>{row.original?.description}</span>;
      },
      canSort: false,
    },
    {
      accessorKey: "validity_days",
      id: "validity_days",
      header: "Validity (in days)",
      cell: ({ row }) => {
        return <span>{row.original?.validity_days}</span>;
      },
    },
    {
      accessorKey: "configuration",
      id: "configuration",
      header: "Configuration",
      cell: ({ row }) => {
        if (row?.original?.type === SubscriptionTypeEnum.DEFAULT) {
          return row.original?.configuration.map((config, idx) => (
            <div
              key={idx}
              style={{ whiteSpace: "nowrap" }}
              className="d-flex gap-4 justify-content-between"
            >
              <span>earningType: {config?.earningType} </span>
              <span>multiplier :{config?.multiplier}</span>
            </div>
          ));
        }
        if (row?.original?.type === SubscriptionTypeEnum.ONE_TIME) {
          return row.original?.configuration.map((config, idx) => (
            <div
              key={idx}
              style={{ whiteSpace: "nowrap" }}
              className="d-flex gap-4 justify-content-between"
            >
              <span> gameMode: {config?.gameMode}</span>
              <span> teamsPerMatch :{config?.teamsPerMatch}</span>
            </div>
          ));
        }
      },
      canSort: false,
    },
    {
      acessorKey: "actions",
      header: "Actions",
      cell: ({ row }) => {
        return (
          <div
            className="d-flex align-items-center"
            style={{ whiteSpace: "nowrap" }}
          >
            <Link
              to={`/subscriptions/${row.original?.id}`}
              state={{ data: row.original, edit: true }}
            >
              <Button variant="secondary" size="sm">
                Edit
              </Button>
            </Link>

            <Button
              onClick={() => {
                setShowConfirmationModal(true);
                setDeleteItemId(row.original?.id);
              }}
              className="ms-2"
              variant="danger"
              size="sm"
            >
              Delete
            </Button>
          </div>
        );
      },
    },
  ];

  const handleDeleteSubscription = async () => {
    setShowConfirmationModal(false);
    console.log("deleteItemId", deleteItemId);
  };

  return (
    <>
      <Row className="mb-2 d-flex">
        <Col md="10">
          <Typography variant="pageTitle">Subscriptions</Typography>
        </Col>
        <Col md="2" className="add-btn">
          <Link to={"/subscriptions/create"}>
            <Button variant="primary">Create</Button>
          </Link>
        </Col>
      </Row>
      <TableWrapper>
        <ReactTable
          columns={columns}
          data={subscriptions?.data || []}
          isLoading={subscriptions?.isLoading}
          hasError={subscriptions?.error?.isError}
          errorMessage={subscriptions?.error?.errorMsg}
        />
      </TableWrapper>
      <ConfirmationModal
        variant="alert"
        show={showConfirmationModal}
        title={`Are you sure, you want to delete the task?`}
        handleClick={() => {
          handleDeleteSubscription();
        }}
        handleClose={() => setShowConfirmationModal(false)}
        secondaryBtnText="Cancel"
        handleSecondaryBtnClick={() => setShowConfirmationModal(false)}
        btnText="Delete"
      />
      <ReactToastr
        show={showToaster?.show}
        message={showToaster?.message}
        closeButton={true}
        title={showToaster?.heading}
        toastBg={showToaster?.variant}
        onClose={handleToasterClose}
        position="top-center"
        autohide
      />
    </>
  );
};

export default Subscriptions;
