import API, { API_METHOD } from "../../api/api";
import { getBase } from "../../apiconfig";
import { URLS } from "../../common/url";
import { handleApiRequest } from "../../utils/apiUtil";
import { HttpStatus } from "../../utils/constants";
import { ERROR_MESSAGE_TYPE } from "../../utils/error/errorMsg";

export const fetchTriviaQuizList = async (payload = {}, config = {}) => {
  const url: string = getBase() + "/api" + URLS.TRIVIA_LIST_URL;
  return await handleApiRequest(API_METHOD.POST, url, payload, config);
};

export const updateTriviaQuiz = async (payload, config = {}) => {
  const { quizId, ...restPayload } = payload;
  const url: string = getBase() + "/api" + URLS.TRIVIA_URL + `/${quizId}`;
  return await handleApiRequest(API_METHOD.PUT, url, restPayload, config);
};

export const fetchTriviaQuizById = async (payload, config = {}) => {
  const { quizId } = payload;
  const url: string = getBase() + "/api" + URLS.TRIVIA_URL + `/${quizId}`;
  return await handleApiRequest(API_METHOD.GET, url, config);
};

export const deleteTriviaQuiz = async (payload, config = {}) => {
  const { quizId } = payload;
  const url: string = getBase() + "/api" + URLS.TRIVIA_URL + `/${quizId}`;
  return await handleApiRequest(API_METHOD.DELETE, url, config);
};

export const createTriviaQuiz = async (payload, config = {}) => {
  const url: string = getBase() + "/api" + URLS.TRIVIA_URL;
  return await handleApiRequest(API_METHOD.POST, url, payload, config);
};

export const publishTriviaQuiz = async (payload, config = {}) => {
  const { quizId, ...restPayload } = payload;
  const url: string = getBase() + "/api" + URLS.TRIVIA_URL + `/${quizId}`;
  return await handleApiRequest(API_METHOD.PUT, url, restPayload, config);
};

export const fetchBannerPresignedUrl = async (
  fileName: string,
  config = {},
) => {
  try {
    const url: string =
      getBase() +
      "/api" +
      URLS.TRIVIA_BANNER_PRESIGNED_URL.replace("{fileName}", fileName);
    const data: any = await API.get(url, config);
    if (data.status === 200 || data.status === 201) {
      if (data?.data?.data?.error)
        // eslint-disable-next-line no-throw-literal
        throw {
          status: HttpStatus.BAD_REQUEST,
          message:
            data?.data?.data?.error ||
            ERROR_MESSAGE_TYPE[HttpStatus.BAD_REQUEST],
        };
      else return data;
    } else {
      // eslint-disable-next-line no-throw-literal
      throw {
        status: data.status,
        message: data?.data?.message || ERROR_MESSAGE_TYPE[data?.status],
      };
    }
  } catch (error) {
    return error;
  }
};

export async function uploadBannerToPresignedUrl(
  presignUrl: string,
  file: any,
  config = {},
) {
  try {
    const data: any = await API.put(presignUrl, file, config);
    if (data.status === 200 || data.status === 201) {
      return data;
    } else {
      // eslint-disable-next-line no-throw-literal
      throw {
        status: data.status,
        message: data?.data?.message || ERROR_MESSAGE_TYPE[data?.status],
      };
    }
  } catch (error) {
    return error;
  }
}
