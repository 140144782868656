import * as Yup from "yup";
import { gameModesOptions } from "../Tasks/tasksUtils";

export enum SubscriptionTypeEnum {
  DEFAULT = "DEFAULT",
  ONE_TIME = "ONE_TIME",
}

enum CurrencyType {
  STARS = "TG_STARS",
}

enum EarningType {
  TASK = "Tasks",
  REFERRAL = "Referral",
  GAME = "Game",
}

export const subscriptionTypes = [
  {
    label: SubscriptionTypeEnum.DEFAULT,
    value: SubscriptionTypeEnum.DEFAULT,
  },
  {
    label: SubscriptionTypeEnum.ONE_TIME,
    value: SubscriptionTypeEnum.ONE_TIME,
  },
];

export const earningTypes = [
  {
    label: EarningType.TASK,
    value: EarningType.TASK,
  },
  {
    label: EarningType.REFERRAL,
    value: EarningType.REFERRAL,
  },
  {
    label: EarningType.GAME,
    value: EarningType.GAME,
  },
];

export const currencyTypes = [
  {
    label: CurrencyType.STARS,
    value: CurrencyType.STARS,
  },
];

export const initialCreateSubscriptionValues = {
  type: SubscriptionTypeEnum.DEFAULT,
  version: 1,
  amount: 0,
  currency_code: CurrencyType.STARS,
  name: "",
  description: "",
  validity_days: 0,
  configuration: [
    {
      earningType: EarningType.TASK,
      multiplier: 1,
    },
  ],
};

export const subscriptionSchema = Yup.object().shape({
  type: Yup.string().required("type is required"),
  version: Yup.number()
    .required("version is required")
    .min(0, "cannot be less than 0"),
  amount: Yup.number().required("amount is required"),
  currency_code: Yup.string().required("currency is required"),
  name: Yup.string().required("name is required"),
  description: Yup.string().required("description is required"),
  validity_days: Yup.number().required("validity is required"),
  configuration: Yup.mixed().when("type", (type, schema) => {
    if (type[0] === "DEFAULT") {
      return Yup.array().of(
        Yup.object().shape({
          earningType: Yup.string().required("earningType is required"),
          multiplier: Yup.number().required("multiplier is required"),
        }),
      );
    } else if (type[0] === "ONE_TIME") {
      return Yup.array().of(
        Yup.object().shape({
          gameMode: Yup.string().required("gameMode is required"),
          teamsPerMatch: Yup.number()
            .required("teamsPerMatch is required")
            .min(1, "cannot be less than 1"),
        }),
      );
    }
    return schema;
  }),
});

export const getAvailableEarningTypesOptions = (configuration) => {
  const availableEarningTypes = earningTypes.filter(
    (earningType) =>
      !configuration.some((config) => config.earningType === earningType.value),
  );
  return availableEarningTypes;
};

export const getAvailableGameModeOptions = (configuration) => {
  const availableGameModeOptions = gameModesOptions.filter(
    (gameModeOption) =>
      !configuration.some(
        (config) => config?.gameMode === gameModeOption.value,
      ),
  );
  return availableGameModeOptions;
};

export const getDefaultConfigValues = (type: SubscriptionTypeEnum) => {
  if (type === SubscriptionTypeEnum.DEFAULT) {
    return [
      {
        earningType: EarningType.TASK,
        multiplier: 1,
      },
    ];
  }
  if (type === SubscriptionTypeEnum.ONE_TIME) {
    return [
      {
        gameMode: gameModesOptions[0].value,
        teamsPerMatch: 1,
      },
    ];
  }
};

export const dummySubsData = [
  {
    id: "1",
    type: "DEFAULT",
    version: 1,
    amount: 50,
    currency_code: "TG_STARS",
    name: "demo",
    description: " a quick brown fox jumps over the lazy dog",
    validity_days: 10,
    configuration: [
      {
        earningType: "Tasks",
        multiplier: 1,
      },
      {
        earningType: "Referral",
        multiplier: 1.5,
      },
    ],
  },
  {
    id: "2",
    type: "ONE_TIME",
    version: 1,
    amount: 30,
    currency_code: "TG_STARS",
    name: "demoSubscription",
    description: " a quick brown fox jumps over the lazy dog",
    validity_days: 5,
    configuration: [
      {
        gameMode: "Tg_TripleThreat",
        teamsPerMatch: 1,
      },
      {
        gameMode: "Tg_Fantasy",
        teamsPerMatch: 2,
      },
    ],
  },
];
